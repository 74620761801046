import VueCookie from 'vuecookie'

function readUserInfo(state) {
    let saved = VueCookie.get('user_info');
    if (saved) {
        try {
            const payload = JSON.parse(saved);
            if (payload) {
                state.name = payload.name;
                state.token = payload.token;
                state.email = payload.email;
                state.role_level = payload.role_level;
                state.user_id = payload.user_id;
                state.department = payload.department;
                state.time = payload.time;
                state.disabled = parseInt(payload.disabled);
                state.city = payload.city;
            }
        } catch (e) {
            console.error(e);
        }
    }
}

function clearUserInfo(state) {
    state.name = '';
    state.id = '';
    state.token = '';
    state.email = '';
    state.role_level = 0;
    state.user_id = '';
    state.department = '';
    state.time = 0;
    state.disabled = 0;
    state.city = '';
}

export default {
    namespaced: true,
    state: {
        name: '',
        token: '',
        email: '',
        role_level: 0,
        user_id: '',
        mobile: '',
        time: 0,
        disabled: 0,
        logo: null,
        city:'',
    },
    mutations: {
        setUserInfo(state, payload) {
            state.name = payload.name;
            if (payload.token) state.token = payload.token;
            state.email = payload.email;
            state.role_level = payload.role_level;
            state.user_id = payload.user_id;
            state.department = payload.department;
            state.disabled = parseInt(payload.disabled);
            state.city = payload.city;
            payload.token = state.token;
            payload.time = new Date().getTime();
            VueCookie.set('user_info', JSON.stringify(payload), {expires: '2D'});
        },

        setUserTime(state) {
            state.time = new Date().getTime();
            VueCookie.set('user_info', JSON.stringify(state), {expires: '2D'});
        },

        readUserInfo(state) {
            readUserInfo(state);
        },
        clear(state) {
            clearUserInfo(state);
            VueCookie.delete('user_info');
        },
    },
    actions: {},
    getters: {
        user(state) {
            if (!(state.token && state.name && state.id)) {
                readUserInfo(state);
            }
            // 时间超过了，token就自动清除
            const time = new Date().getTime();
            // 时间超过了，token就自动清除
            if (state.time && (time - state.time) / 1000 >= 3600 * 4) {
                clearUserInfo(state)
            }
            return state;
        }
    }
}
