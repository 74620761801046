import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {i18n} from './plugins/'
import store from './store'
import config from './config/'
import filters  from './common/filters'

// Router Hook
router.beforeEach((to, from, next) => {
    let user = store.getters['user/user'];

    //检查身份
    if (to.meta && to.meta.public) {
        // 公开的，直接就next了。
        next();
    } else {
        //这里必须检查身份
        const time = new Date().getTime();
        if (!user.token || (time - user.time) / 1000 >= 3600 * 4) {
            //没登录的一律去登录页面,4小时没动作的也要重新登录下
            //直接去GO-SSO页面，由GO-SSO来判断是否登录
            window.location.href="/go/sso?_t=" + encodeURIComponent(to.path);
            // next({name: 'go-sso', query: {_t: encodeURIComponent(to.path)}});
        } else {
            // 检测级别，若是不够则跳到错误页面 /
            const role_level = parseInt(user.role_level);
            let can = true;
            for (let i = 0; i < to.matched.length; i++) {
                const route = to.matched[i];
                //对每一层的目录都要过滤一下，看看能不能访问
                if (route.meta && route.meta.level) {
                    can = route.meta.level <= role_level
                }
            }
            if (!can) {
                next({name: 'prohibit'});
            } else {
                next();
            }
        }
    }
});

router.afterEach(() => {
});

Vue.prototype.$organization = config.org;
Object.keys(filters).forEach(key=>{
    Vue.filter(key,filters[key])
})


//const app =
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');

store.dispatch('preset/loadPreset').then();
