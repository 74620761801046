let prefix = "";
let public_portal = true;
let dark_mode = true;
//以下内容需要进行修改或者增加
const org = process.env.VUE_APP_API_ORG.toLowerCase();
if ( org === 'nokia') {
    prefix = 'nokia-';
    public_portal = false;
    dark_mode = false;
    document.getElementsByTagName('body')[0].style.setProperty('--public-highlight','#2196f3')
    document.getElementsByTagName('body')[0].style.setProperty('--public-carousel-text','#2196f3')
    document.getElementsByTagName('body')[0].style.setProperty('--public-carousel-color','#092942')
} else if (org === "fudan" ) {
    //color
} else if (org === "sues" ) {
    //color
    document.getElementsByTagName('body')[0].style.setProperty('--public-highlight','#2196f3')
    document.getElementsByTagName('body')[0].style.setProperty('--public-carousel-text','#2196f3')
    document.getElementsByTagName('body')[0].style.setProperty('--public-carousel-color','#092942')
} else if (org === "ecnu") {
    //color
    document.getElementsByTagName('body')[0].style.setProperty('--public-highlight','#cb2741')
    document.getElementsByTagName('body')[0].style.setProperty('--public-carousel-text','#ffffff')
    document.getElementsByTagName('body')[0].style.setProperty('--public-carousel-color','#621117')
} else if (org === "shufe") {
    //color
    prefix = 'shufe-';
    document.getElementsByTagName('body')[0].style.setProperty('--public-highlight','#cb2741')
    document.getElementsByTagName('body')[0].style.setProperty('--public-carousel-text','#ffffff')
} else if (org === "pep") {
    prefix = 'pep-';
}

//以上内容进行修改和增加
const api_url = process.env.VUE_APP_API_ROOT?process.env.VUE_APP_API_ROOT:window.location.origin+'/api/v1'
const sign_in_url = api_url.replace('api/v1','sign-in')
const host_url = api_url.replace('api/v1','');

// 导出内容
export default {
    //如果设置sso，则不显示登录页面，直接跳转到sso的页面
    sso:process.env.VUE_APP_SSO_LOGIN,
    sso_out:process.env.VUE_APP_SSO_LOGOUT,
    sso_type:process.env.VUE_APP_SSO_TYPE?process.env.VUE_APP_SSO_TYPE.toLowerCase():'oauth2',
    sso_force:process.env.VUE_APP_SSO_FORCE,
    sso_play:process.env.VUE_APP_SSO_PLAY || process.env.VUE_APP_SSO_FORCE,

    wechat:process.env.VUE_APP_USE_WECHAT,

    //学校的org缩写
    org:process.env.VUE_APP_API_ORG,
    org_name:process.env.VUE_APP_API_ORG_NAME,
    dark:dark_mode,

    //登录的template
    template_prefix:prefix,
    public_portal:public_portal,
    //系统登录的url
    signin_url:sign_in_url,
    //系统的url
    host_url:host_url,
    //系统的apiUrl
    api_url:api_url,
    build_time:process.env.VUE_APP_BUILD_TIME,

}
