const locale = {
    "TITLE": {
        "LIVE":"LIVE",
        "PLAYBACK":"REPLAY",
        "ERROR": "Error",
        "INFO": "Information",
        "OK":"OK",
        "CANCEL":"Cancel",
        "ADMIN":"Administrator",
        "SIGN-IN":"SIGN IN",
        'GREETING':"Hi",
        'GO-BACK':"Go Back",
        "SUBMIT":"Submit",
        "SIGN-OUT":"Sign Out",
        "SPEAKER":"Speaker(s)",
        "TIME":"Time",
        "DESCRIPTION":"Description",
        "ENTER-LIVE":"Watch Live Event",
        'COMMENTS':'Comments',
        'INPUTS':"Mine",
        "SEND":"Send",
        "SOURCE":"Media Source",
        "SHANGHAI":"Shanghai",
        "HANGZHOU":"Hangzhou",
        "BEIJING":"Beijing",
        "LANGUAGE":"Language",
        "PRIVATE-QUESTION":"Private Comment",
        "ANNOUNCE":"Announcement",
        "LATEST-EVENTS":"Latest events",
        "SEARCH":"Search",
        "LIVE-EVENT":"LIVE EVENT",
        "PLAY-BACK":"PLAY BACK",
        "MORE":"more...",
        "HOT-EVENTS":"Hottest",
        "MORE-EVENTS":"MORE EVENTS",
        "WELCOME":"WELCOME",
        "SSO":"SSO",
        "LOCAL":"LOCAL",
        "SIGN-IN-TO-COMMENT":"comment after sign in",
        "SUBJECT":"Subject",
        "DATE":"Date",
        "SECTION":'Section',
        "1MONTH":"One Month",
        "TODAY":"Today",
        "6MONTH":"Half Year",
        "1YEAR":"One Year",
        "3YEAR":"Three Year",
        "RANGE":"Range",
        "UNAUTHORIZED":"You are not authorized",
        "SELECT-SECTION":'Choose a section',
        "SELECT-FILTER":"Set filter",
        "NOT-SELECTED":"Not selected",
        "SELECT-DATE-RANGE":"Date range",
        "CONFIRM":'OK',
        "SELECT-SECTION-DATE":"Select",
        "FILTER":"Filter",
        "NO-MORE":"No more ...",
        "BACK":"Go Back.",
        "NETID":"NetID Login",
        "SSO-LOGIN":"SSO Login",
        "CLOSE":"Close",

    },

    "MESSAGE": {
        "NO-RESPONSE": 'You have not responded for a long time, the system is automatically logged out.',
        "CONNECT-FAILURE":"Connection failure, please try again later.",
        "WAIT":"Please wait ...",
        "GO-SSO":"SSO Login Page",
        "CLICK-SSO":"Click to <b>SSO</b> Page",
        "RECOMMEND":"*Recommend to use Chrome,Safari,Firefox & Edge*",
        "UNAUTHORIZED":"Access denied, click to go back!",
        'NOT-FOUND':"Event doesn't exist!",
        'NOT-INVITED':"Sorry, you are not in the invitation list!",
        'INTERNAL-ONLY':'Sorry, this event is only available to campus access!',
        "INPUT-PASSCODE":"Input Passcode Below",
        "USERID-PASSWORD":"User ID or password cannot be empty",
        "CONFIRM-QUIT":"About to log out, do you want to continue?",
        'ON-LIVE':"Event has started",
        "EVENT-START":"Event will start in ",
        "EVENT-CLOSED":'This event has ended',
        "DVR-NOT-READY":"Sorry, the DVR file is NOT ready, please visit later ...",
        'WATCH-DVR':'Watch Replay',
        "COMMENT-SENT":"The comment has been sent, please wait for a reply.",
        "NO-DATA":"NO EVENT",
        "WELCOME":"Welcome to the login screen of the live platform. " +
            "If you are an on-campus user, please click the SSO button to the university's unified identity sign in site to log in. " +
            "If you are an off-campus user, you will need web master to create an account for you.",
        "SSO-ERROR":"Wrong SSO return message, re-login again please.",
        "QUESTION":"I have a question...",
        "NO-COMMENTS":"No Comments..."

    },
    "MENU":{
        "HOME":"Home",
        "LIVE":"Live",
        "PLAYBACK":"Playback",
        "CHANNEL":"Channel",
        "ADMIN":"Admin",
    },
    "ERROR":{
        "UNAUTHORIZED": "Unauthorized!"
    }
}

export default {
    ...locale
};
