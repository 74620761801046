import Vue from 'vue'
import Element from 'element-ui'
//import languageEN from 'element-ui/lib/locale/lang/en'
import languageCN from 'element-ui/lib/locale/lang/zh-CN'
import locale from 'element-ui/lib/locale'
import 'element-ui/lib/theme-chalk/index.css'
//import 'element-theme-default/lib/index.css'

locale.use(languageCN)

Vue.use(Element)
