import Vue from 'vue';

Vue.directive('pictureViewer', {
    bind(el) {

        el.classList.add('picture-dialog--outer');
        const dialogHeaderEl = el.querySelector('.el-dialog__header');
        dialogHeaderEl.style.borderBottom = 'none';
        dialogHeaderEl.style.height = '0';
        dialogHeaderEl.style.padding = '0';

        const headerEl = dialogHeaderEl.querySelector('.el-dialog__title');
        headerEl.style.display = 'none';

        const closeButtonEl = dialogHeaderEl.querySelector('.el-dialog__headerbtn');
        if (closeButtonEl)
            closeButtonEl.classList.add('picture-dialog-close--btn');

        const dialogEl = el.querySelector('.el-dialog');
        if (dialogEl) {
            dialogEl.classList.add('picture-dialog--wrap');
            dialogEl.style = '';
        }
    }
});
