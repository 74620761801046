export default {
    namespaced: true,
    state: {
        loading: false,
        processing: false,
        collapsed: null,
        filter:'',
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload;
        },
        start(state) {
            state.loading = true
        },
        stop(state) {
            state.loading = false
        },
        setCollapsed(state, value) {
            state.collapsed = value;
            localStorage.setItem('collapsed', state.collapsed);
        },
        setFilter(state,payload) {
            state.filter = payload
        }
    },
    actions: {},
    getters: {
        isCollapsed(state) {
            if (state.collapsed === null) {
                let col = localStorage.getItem('collapsed');
                if (col) {
                    state.collapsed = col === "true"
                } else {
                    state.collapsed = window.innerWidth < 1200;
                    localStorage.setItem('collapsed', state.collapsed);
                }
            }
            return state.collapsed;
        }
    }
}
