import Api from "../config/api";
import axios from "axios";
import VueCookie from "vuecookie";
import {i18n} from "../plugins";

export default {
    namespaced: true,
    state: {
        app: {},
        loaded: false,
        client_token: null,
    },
    mutations: {
        set(state, payload) {
            state.app = payload;
            state.loaded = true;
            if (state.app.name) {
                document.title = state.app.name;
            }
        },
    },
    getters: {
        client_token(state) {
            let token = state.client_token ? state.client_token : VueCookie.get('client_token');
            if (!token) {// 判断是否有token,没有的话生成token
                token = "CT" + (new Date().getTime().toString(24));
                VueCookie.set('client_token', token, {expires: '7D'});
                state.client_token = token;
            } else {
                state.client_token = token;
            }
            return state.client_token;
        },
        english() {
            return i18n.locale === 'en';
        }
    },
    actions: {
        loadPreset(context) {
            const prefix = "storage/preset.json?_t=" + Math.random();
            const url = Api.url.replace('api/v1', prefix);
            axios.get(url).then(res => {
                if (res.data) {
                    context.commit('set', res.data);
                }
            }).catch(() => {
            });
        }
    }
}
