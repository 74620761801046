//filters

export default {
    number:function (num)  {
        let result = [], counter = 0;
        num = (num || 0).toString().split('');
        for (let i = num.length - 1; i >= 0; i--) {
            counter++;
            result.unshift(num[i]);
            if (!(counter % 3) && i !== 0) {
                result.unshift(',');
            }
        }
        return result.join('');
    },
    str16:function(str) {
        if (str) return str.substring(0,16);
        return "";
    }
}
